<template>
  <div>
    <HomeSwiper
      @search="search($event)"
      src="/images/banner.jpg"
      placeholder-text="搜索产品"
    ></HomeSwiper>

    <div class="context">
      <baidu-map
        style="
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
          height: 100vh;
        "
        id="allmap"
        @ready="mapReady"
        @click="getLocation"
        :scroll-wheel-zoom="false"
      >
        <div
          style="
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 8px;
          "
        >
          <bm-auto-complete
            v-model="searchJingwei"
            :sugStyle="{ zIndex: 999999 }"
            style="width: 98%; margin-right: 2%"
          >
            <el-input v-model="searchJingwei" placeholder="输入地址"></el-input>
          </bm-auto-complete>
          <el-button type="primary" @click="getBaiduMapPoint">搜索</el-button>
        </div>
        <bm-map-type
          :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
          anchor="BMAP_ANCHOR_TOP_LEFT"
        ></bm-map-type>
        <bm-marker
          v-if="infoWindowShow"
          :position="{ lng: longitude, lat: latitude }"
        >
          <bm-label
            content=""
            :labelStyle="{ color: 'red', fontSize: '24px' }"
            :offset="{ width: -35, height: 30 }"
          />
        </bm-marker>
        <bm-info-window
          :position="{ lng: longitude, lat: latitude }"
          :show="infoWindowShow"
          @clickclose="infoWindowClose"
        >
          <p>地址:{{ this.address }}</p>
        </bm-info-window>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import HomeSwiper from "@/components/Cms/HomeSwiper";
export default {
  data() {
    return {
      searchJingwei: "",
      infoWindowShow: false,
      longitude: "",
      latitude: "",
      point: "",
      address: "乌鲁木齐市水磨沟区亚欧博览会",
    };
  },
  components: {
    HomeSwiper,
  },
  methods: {
    search(searchText) {
      window.location.href = `/cms/goods?keyword=${searchText}`;
    },
    //地图初始化
    mapReady({ BMap, map }) {
      // 选择一个经纬度作为中心点
      this.point = new BMap.Point(87.617733, 43.792818);
      map.centerAndZoom(this.point, 12);
      this.BMap = BMap;
      this.map = map;

      let that = this;
      let myGeo = new this.BMap.Geocoder();
      myGeo.getPoint(this.address, function (point) {
        if (point) {
          that.map.centerAndZoom(point, 15);
          that.latitude = point.lat;
          that.longitude = point.lng;
          that.infoWindowShow = true;
        }
      });
    },
    //点击获取经纬度
    getLocation(e) {
      // console.log(e);
      // this.longitude=e.point.lng
      // this.latitude=e.point.lat
      // this.infoWindowShow=true
    },
    // 搜索后点击定位标记
    getBaiduMapPoint() {
      let that = this;
      let myGeo = new this.BMap.Geocoder();
      myGeo.getPoint(this.searchJingwei, function (point) {
        if (point) {
          that.map.centerAndZoom(point, 15);
          that.latitude = point.lat;
          that.longitude = point.lng;
          that.infoWindowShow = true;
        }
      });
    },
    //信息窗口关闭
    infoWindowClose() {
      this.latitude = "";
      this.longitude = "";
      this.infoWindowShow = false;
    },
    //回显
    setPoint(lat, lng) {
      var p = new BMap.Point(lng, lat);
      this.latitude = lat;
      this.longitude = lng;
      this.infoWindowShow = true;
      this.map.centerAndZoom(p, 16);
    },
    //地图初始化
    init() {
      var p = new BMap.Point(120.215512, 30.253083);
      this.latitude = "";
      this.longitude = "";
      this.searchJingwei = "";
      this.infoWindowShow = false;
      this.map.centerAndZoom(p, 12);
    },
  },
};
</script>

<style scoped>
#allmap {
  height: 450px;
  width: 100%;
  margin: 0;
}
.context {
  padding: 50px;
  max-width: 1280px;
  margin: auto;
}
</style>
